import React from 'react'
import { useTranslation } from 'react-i18next';


export default function ActiveInfoTree() {
    const colors = {
        active: "#2d882d",
        customer: "#6d6d6d",
        inactive: "#c61616",
        pending: "#c2c221"
    }
    const { t } = useTranslation();
    return (
        <div><div className="profileStatusSecTree">
            <div className="profileStatusBg">
                <div className="profileStatusContDetailSecTreeView">
                    <div className="treeViewCircle" style={{ backgroundColor: colors['active'] }}>
                    </div>
                    <div className="tree-view-desc">
                        <h6>{t("active")}</h6>
                    </div>
                </div>
                <div className="profileStatusContDetailSecTreeView">
                    <div className="treeViewCircle" style={{ backgroundColor: colors['pending'] }}>
                    </div>
                    <div className="tree-view-desc">
                        <h6>{t("pending")}</h6>
                    </div>
                </div>
                <div className="profileStatusContDetailSecTreeView">
                    <div className="treeViewCircle" style={{ backgroundColor: colors['inactive'] }}>
                    </div>
                    <div className="tree-view-desc">
                        <h6>{t("inactive")}</h6>
                    </div>
                </div>
                <div className="profileStatusContDetailSecTreeView">
                    <div className="treeViewCircle" style={{ backgroundColor: colors['customer'] }}>
                    </div>
                    <div className="tree-view-desc">
                        <h6>{t("customer")}</h6>
                    </div>
                </div>

            </div>
        </div></div>
    )
}
