import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import { ApiHook } from "../../hooks/apiHook";
import { toast } from "react-toastify";

const MonthlyActiveStatus = ({ data }) => {
    const activateMonth = ApiHook.CallActivateMonth()
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(moment().format('YYYY'));

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };
    const handleInactivePayment = async (data) => {
        const response = await activateMonth.mutateAsync(data)
        if (response.status) {
            window.location.href = response.data.link
        } else {
            toast.error(t("someErrorOccured"))
        }
    }
    return (
        <div className={"col-md-4"}>
            <div className="joinings_viewBox teamperfomance">
                <div className="joinings_viewBox_head">
                    <h5>{t("monthlyActiveStatus")}</h5>
                </div>
                {data && (
                    <ul
                        className="teamPerfomance_tab nav nav-tabs mb-3"
                        id="ex1"
                        role="tablist"
                    >
                        {data.yearRange.map((e) => {
                            return <>
                                <li className="nav-item" role="presentation">
                                    <Link
                                        className={`nav-link${activeTab == e ? " active" : ""
                                            }`}
                                        id={`ex1-tab-${"earnings"}`}
                                        data-bs-toggle="tab"
                                        role="tab"
                                        aria-controls={"earnings"}
                                        aria-selected={activeTab == e}
                                        onClick={() => handleTabClick(e)}
                                    >
                                        {e}
                                    </Link>
                                </li>
                            </>
                        })

                        }
                    </ul>
                )}

                <div className="tab-content" id="ex2-content">
                    <div className="joinings_viewBox teamperfomance m-a-dashboard">
                        {
                            data.yearlyDetails[activeTab].map((el) => {
                                return <>
                                    <div className="row " style={{ padding: "8px" }}>
                                        <div className="col-md-8">
                                            {el.month}
                                        </div>
                                        <div className="col-md-4" style={{ textAlign: "center" }}>
                                            {el.active ?
                                                <div className="rightSide_top_user_dropdown_package active_bg">{t("active")}</div>
                                                :
                                                <div className="rightSide_top_user_dropdown_package inactive_bg hover-pointer" onClick={() => handleInactivePayment({ month: el.monthDigit, year: el.year })}>{t("inactive")}</div>
                                            }
                                        </div>
                                    </div>
                                </>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MonthlyActiveStatus;
